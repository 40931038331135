.lowbackground {
  background: rgba(255, 255, 255, 0.5);
  font-size: min(calc(8vw), 50px);
  overflow: hidden;
  text-align: center;
}

.largeTxt {
  font-size: min(calc(2vw + 2vh + 3vmin), 36px);
}

.timerDiv {
  width: 400px;
}

@media only screen and (max-width: 1024px) {
  .demoHeight {
    width: 100vw;
    height: 500px;
  }
}

@media only screen and (min-width: 1024px) {
  .demoHeight {
    width: 500px;
    height: 500px;
  }
}
