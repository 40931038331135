.logoFont {
  font-family: "Righteous", cursive;
}
/* Animation keyframe */
@keyframes pulse {
  50% {
    opacity: 0.5;
  }
}

/* Class for element with animation */
.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
